import React from "react";
import breakpoints from "../helpers/styles/breakpoints";
import styled from "styled-components";
import typography from "../helpers/styles/typography";
import { Link } from "gatsby";
import { LinkStyle } from "../helpers/styles/LinkStyle";
import colors from "../helpers/styles/colors";

const Container = styled.div`
  padding: 20px 8px;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  > * {
    &:first-child {
      display: flex;
      svg {
        margin: 0px 10px;
        width: 38px;
        height 38px;
        ${breakpoints.tablet} {
            width: 50px;
            height 50px;
        }
      }
    }
  }
  p:not(:last-of-type) {
    padding-bottom: 20px;
  }
  span {
    font-weight: bold;
  }
  @media ${breakpoints.tablet} {
    padding: 30px 30px;
}
`;

const Body = styled.p`
  font: ${typography.subTitle};

  @media ${breakpoints.mobileS} {
    font-size: 16px;
  }
`;

export const SupportLinks = styled(Link)`
  padding: 0px;
  ${LinkStyle()}
`;

export const AnchorLink = styled.a`
  padding: 0px;
  ${LinkStyle()}
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.visitedLinkBlue};
`;

export const BannerWhite = ({ icon, children }) => {
  return (
    <Container>
      <div>
        {icon && <div>{icon}</div>}
        <div>
          <Body>{children}</Body>
        </div>
      </div>
    </Container>
  );
};
