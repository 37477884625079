(function launchGenesysChat(deploymentId) {
  const chatName = "Genesys";
  window["_genesysJs"] = chatName;
  window[chatName] =
    window[chatName] ||
    function () {
      (window[chatName].q = window[chatName].q || []).push(arguments);
    };
  window[chatName].t = 1 * new Date();
  window[chatName].c = {
    environment: "euw2",
    deploymentId,
  };
  function fireReadyEvent() {
    window.dispatchEvent(new Event("onGenesysLauncherReady"));
    window.Genesys("unsubscribe", "Launcher.ready", fireReadyEvent);
  }
  const genesysScript = document.createElement("script");
  genesysScript.onload = function () {
    window.Genesys("subscribe", "Launcher.ready", fireReadyEvent);
  };
  genesysScript.async = 1;
  genesysScript.src = "https://apps.euw2.pure.cloud/genesys-bootstrap/genesys.min.js";
  document.head.appendChild(genesysScript);
})("6e5f874b-d92a-4e0b-ad98-ca731c816257");

window.genesysOpen = function toggleMessenger() {
  window.Genesys(
    "command",
    "Messenger.open",
    {},
    function (o) {},
    function (o) {
      window.Genesys("command", "Messenger.close");
    },
  );
};
